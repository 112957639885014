import React, {useEffect} from 'react';
import {navigate, PageProps, Link} from 'gatsby';
import {makeStyles} from '@material-ui/core/styles';
import {getRedirectLanguage} from '../utils';
import {homeVisibleStores} from '../../configs/site';

const useStyles = makeStyles({
    root: {
        visibility: 'hidden',
    },
    link: {
        display: 'block',
    },
});

export function Home({location}: PageProps) {
    const classes = useStyles();
    useEffect(() => {
        const {search} = location;
        navigate(`/${getRedirectLanguage(navigator)}/${search || ''}`, {
            replace: true,
        });
    }, []);
    const stores = homeVisibleStores?.sort((a: any, b: any) => {
        if (b.lang === 'fr-fr' && a.lang !== 'fr-fr') return 1;
        if (b.lang !== 'fr-fr' && a.lang === 'fr-fr') return -1;
        return (a.label || '').localeCompare(b.label);
    });
    return (
        <div className={classes.root}>
            {stores &&
                stores.map((store: any) => (
                    <Link
                        className={classes.link}
                        to={`/${store.lang}/`}
                        key={store.lang}
                    >
                        {store.label}
                    </Link>
                ))}
        </div>
    );
}

export default Home;
